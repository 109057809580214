// Generated by Framer (b2780b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["aWWuBcQSr", "YFe22bI3M"];

const serializationHash = "framer-7jCOH"

const variantClassNames = {aWWuBcQSr: "framer-v-13gt392", YFe22bI3M: "framer-v-1kdnp9t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "aWWuBcQSr", "Variant 2": "YFe22bI3M"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aWWuBcQSr", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aWWuBcQSr", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap18ey10o = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("YFe22bI3M")
})

const onTap1hekrqb = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("aWWuBcQSr")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "YFe22bI3M") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13gt392", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"aWWuBcQSr"} onTap={onTap18ey10o} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({YFe22bI3M: {"data-framer-name": "Variant 2", onTap: onTap1hekrqb}}, baseVariant, gestureVariant)}><motion.div className={"framer-48c70e"} layoutDependency={layoutDependency} layoutId={"s4qcpqFYB"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{YFe22bI3M: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1r8um7k"} layoutDependency={layoutDependency} layoutId={"VZ02MJEZL"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-hmna2s"} layoutDependency={layoutDependency} layoutId={"sNcs7LF8Z"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{YFe22bI3M: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7jCOH.framer-zn9ip7, .framer-7jCOH .framer-zn9ip7 { display: block; }", ".framer-7jCOH.framer-13gt392 { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-7jCOH .framer-48c70e { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-7jCOH .framer-1r8um7k { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-7jCOH .framer-hmna2s { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-7jCOH.framer-v-1kdnp9t.framer-13gt392 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-7jCOH.framer-v-1kdnp9t .framer-48c70e { top: calc(51.61290322580647% - 2px / 2); }", ".framer-7jCOH.framer-v-1kdnp9t .framer-hmna2s { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YFe22bI3M":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNjexQweLc: React.ComponentType<Props> = withCSS(Component, css, "framer-7jCOH") as typeof Component;
export default FramerNjexQweLc;

FramerNjexQweLc.displayName = "hamburger";

FramerNjexQweLc.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerNjexQweLc, {variant: {options: ["aWWuBcQSr", "YFe22bI3M"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerNjexQweLc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})